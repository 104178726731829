import React, {Suspense, lazy} from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyle } from './global.styles';

import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import { AUTH_TOKEN_TYPES } from "./user/types/auth.types";

import Spinner from "./utils/components/spinner/spinner.component"

import { postCategoryToAddLoader, postCategoryToEditLoader } from './routes/management/posts/post-categories-loader';
import { projectCategoryToAddLoader, projectCategoryToEditLoader } from './routes/management/projects/project-categories-loader';
import { postToAddLoader, postToEditLoader } from './routes/management/posts/posts-loader';
import { projectToAddLoader, projectToEditLoader } from './routes/management/projects/projects-loader';
import { educationToAddLoader, educationToEditLoader } from './routes/management/education/education-loader';
import { awardToAddLoader, awardToEditLoader } from './routes/management/awards/award-loader';
import { faqToAddLoader, faqToEditLoader } from './routes/management/faqs/faq-loader';
import { interestToAddLoader, interestToEditLoader } from './routes/management/interests/interest-loader';
import { currentUserProfileLoader } from './routes/end-user/data-loaders/currentUserProfileLoader';
import { efficiencyLoader } from './routes/management/projects/efficiency-loader';
import MANAGEMENT_MODES from './routes/management/management-modes';
import { featuredToAddLoader, featuredToEditLoader } from './routes/management/projects/featured-loader';
import { skillToAddLoader, skillToEditLoader } from './routes/management/skills/skill-loader';
import { packageToAddLoader, packageToEditLoader } from './routes/management/packages/package-loader';
import { workExperienceToAddLoader, workExperienceToEditLoader } from './routes/management/work-experience/work-experience-loader';
import { languageToAddLoader, languageToEditLoader } from './routes/management/language/language-loader';
import { salesGeneratedLoader } from './routes/management/projects/sales-generated-loader';
import { overallCostLoader } from './routes/management/projects/overall-cost-loader';
import { customerSatisfactionLoader } from './routes/management/projects/customer-satisfaction-loader';
import { postLoader } from './routes/end-user/current-user-profile/blog-post/post-loader';
import { projectLoader } from './routes/end-user/current-user-profile/project/project-loader';
import { certificationToAddLoader, certificationToEditLoader } from './routes/management/certifications/certification-loader';
import { privilegeToAddLoader, privilegeToEditLoader } from './routes/management/users/privilege-loader';
import { roleToAddLoader, roleToEditLoader } from './routes/management/users/role-loader';
import { userToAddLoader, userToEditLoader } from './routes/management/users/user-loader';
import { fileToAddLoader, fileToEditLoader } from './routes/management/files/files-loader';
import { promoToAddLoader, promoToEditLoader } from './routes/management/promos/promos-loader';
import { profileToAddLoader, profileToEditLoader } from './routes/management/settings/profiles-loader';
import { resumeToAddLoader, resumeToEditLoader } from './routes/management/settings/resumes-loader';
import { refereeToAddLoader, refereeToEditLoader } from './routes/management/referees/referee-loader';
import { guideToAddLoader, guideToEditLoader } from './routes/management/guides/guide-loader';
import { guideLoader } from './components/end-user/user-guides/guide-loader';
import { viewNotificationLoader } from './routes/management/notifications/notification-loader';

const CreateEditPost = lazy(() => import('./routes/bedubyte/posts/create-edit-post.component'));
const FollowPolicySettings = lazy(() => import ('./routes/management/settings/follow-policy.component'));
const NotificationSettings =  lazy (() => import('./routes/management/notifications/notification-settings.component'));
const SendNotification = lazy (() => import('./routes/management/notifications/send-notification.component'));
const ReadNotification =  lazy(() =>  import('./routes/management/notifications/read-notification.component'));
const NotificationDashboard = lazy(() =>  import('./routes/management/notifications/notification-dashboard.component'))
const UserGuide = lazy(() => import('./components/end-user/user-guides/user-guide.component'));
const Guides = lazy(() => import('./components/end-user/user-guides/guides.component'));
const Sitemap = lazy(() =>import('./routes/bedubyte/sitemap/sitemap.component'));
const ListGuide =  lazy(() => import('./routes/management/guides/list-guide.component'));
const AddEditGuide = lazy(() =>import('./routes/management/guides/add-edit-guide.component'));
const FAQs = lazy(() => import('./routes/bedubyte/faqs/faqs.component'));
const Welcome = lazy(() => import('./routes/bedubyte/welcome/welcome.component'));
const AddEditReferee = lazy(() => import('./routes/management/referees/add-edit-referee.component'));
const ListReferee = lazy(() => import('./routes/management/referees/list-referee.component'));
const ResumeMenu = lazy(() => import('./routes/management/resume/resume-menu.component'));
const ProjectsMenu = lazy(() => import('./routes/management/projects/projects-menu.component'));
const Password = lazy(() => import('./routes/management/settings/password.component'));
const AddEditResume = lazy(() => import('./routes/management/settings/add-edit-resume.component'));
const ListProfile = lazy(() => import('./routes/management/settings/list-profile.component'));
const ListResume = lazy(() => import('./routes/management/settings/list-resume.component'));
const AddEditProfile = lazy(() => import('./routes/management/settings/add-edit-profile.component'));
const Settings = lazy(() => import('./routes/management/settings/settings.component'));
const EditLocation = lazy(() => import('./routes/management/my/edit-location.component'));
const ListPromo = lazy(() => import('./routes/management/promos/list-promo.component'));
const AddEditPromo = lazy(() => import('./routes/management/promos/add-edit-promo.component'));
const ListFiles = lazy(() => import('./routes/management/files/list-files.component'));
const AddEditFile = lazy(() => import('./routes/management/files/add-edit-file.component'));
const EditProfilePicture = lazy(() => import('./routes/management/my/edit-profile-picture.component'));
const AddEditRole = lazy(() =>import('./routes/management/users/add-edit-role.component'));
const AddEditUser = lazy(() => import('./routes/management/users/add-edit-user.component'));
const ListRole = lazy(() => import('./routes/management/users/list-role.component'));
const AddEditPrivilege =  lazy(() => import('./routes/management/users/add-edit-privilege.component'));
const ListPrivilege = lazy(() => import('./routes/management/users/list-privilege.component'));
const ListUser = lazy(() => import('./routes/management/users/list-user.component'));
const Discover = lazy (() => import('./routes/bedubyte/discover/discover.component'));
const PrivacyPolicy = lazy(() =>import('./routes/bedubyte/privacy-policy/privacy-policy.component'));
const TermsOfService = lazy(() =>import('./routes/bedubyte/terms-of-service/terms-of-service.component'));
const ListCertification = lazy(() => import('./routes/management/certifications/list-certification.component'));
const AddEditCertification = lazy (() => import('./routes/management/certifications/add-edit-certification.component'));
const ListProjectOverallCosts = lazy(() => import('./routes/management/projects/list-project-overall-cost.component'));
const AddEditProjectOverallCost = lazy(() => import('./routes/management/projects/add-edit-project-overall-costs.component'));
const ListProjectCustomerSatisfactions = lazy(() => import('./routes/management/projects/list-project-customer-satisfactions.component'));
const AddEditProjectCustomerSatisfaction = lazy(() => import('./routes/management/projects/add-edit-project-customer-satisfaction.component'));
const ListProjectSalesgenerated = lazy (() =>import('./routes/management/projects/list-project-sales-generated.component'));
const AddEditProjectSalesGenerated = lazy(() =>import('./routes/management/projects/add-edit-project-sales-generated.component'));
const ListLanguage = lazy(() => import('./routes/management/language/list-language.component'));
const AddEditLanguage = lazy(() => import('./routes/management/language/add-edit-language.component'));
const ListWorkExperience = lazy(() => import('./routes/management/work-experience/list-work-experience.component'));
const AddEditWorkExperience = lazy(() =>import('./routes/management/work-experience/add-edit-work-experience.component'));
const ListPackage = lazy(() => import('./routes/management/packages/list-package.component'));
const AddEditPackage = lazy(() => import('./routes/management/packages/add-edit-package.component'));
const ListSkill = lazy(() => import('./routes/management/skills/list-skill.component'));
const AddEditSkill = lazy( () => import('./routes/management/skills/add-edit-skill.component'));
const ListFeaturedProject = lazy(() => import('./routes/management/projects/list-featured-project.component'));
const AddEditFeaturedProject = lazy( () =>import('./routes/management/projects/add-edit-featured-project.component'));
const AddEditProjectEfficiency = lazy(() => import('./routes/management/projects/add-edit-project-efficiency.component'));
const ListProjectEfficiency  = lazy(() => import('./routes/management/projects/list-project-efficiency.component'));
const MyDashboard = lazy(() => import('./routes/management/my/my-dashboard.component'));
const Logout = lazy(() => import('./components/bedubyte/auth/logout.component'));
const MyAccount = lazy(() => import('./routes/management/my/my-account.component'));
const EditMyAccount = lazy (() => import('./routes/management/my/edit-my-account.component'));
const ListPostCategory = lazy (() => import('./routes/management/posts/list-post-category.component'));
const AddEditPostCategory = lazy(() => import('./routes/management/posts/add-edit-post-category.component'));
const ListProjectCategory = lazy(() => import('./routes/management/projects/list-project-category.component'));
const AddEditProjectCategory = lazy (() => import('./routes/management/projects/add-edit-project-category.component'));
const ListPost = lazy (()  => import('./routes/management/posts/list-post.component'));
const AddEditPost = lazy (() => import('./routes/management/posts/add-edit-post.component'));
const ListProject =  lazy(() => import ('./routes/management/projects/list-project.component'));
const AddEditProject =lazy (() => import('./routes/management/projects/add-edit-project.component'));
const ListEducation = lazy  (()=> import('./routes/management/education/list-education.component'));
const AddEditEducation = lazy( () => import('./routes/management/education/add-edit-education.component'));
const ListAward =  lazy(() => import('./routes/management/awards/list-award.component'));
const AddEditaward =  lazy(() => import ('./routes/management/awards/add-edit-award.component'));
const ListFaq =  lazy (() => import ('./routes/management/faqs/list-faqs.component'));
const AddEditFaq = lazy( () => import('./routes/management/faqs/add-edit-faq.component'));
const ListInterest = lazy( () => import('./routes/management/interests/list-interests.component'));
const AddEditInterest = lazy( () => import( './routes/management/interests/add-edit-interest.component'));
const CurrentProfile = lazy( () => import('./routes/end-user/current-user-profile/current-user-profile.component'))
const ContactMe = lazy( () => import( './routes/end-user/current-user-profile/contact/contact-me.component'))
const Project = lazy( () => import( './routes/end-user/current-user-profile/project/project.component'))
const BlogPost = lazy( () => import( './routes/end-user/current-user-profile/blog-post/blog-post.component'))
const Resume = lazy( () => import( './routes/end-user/current-user-profile/resume/resume.component'))
const ServicesAndPricing = lazy( () => import( './routes/end-user/current-user-profile/services-and-pricing/services-and-pricing.component'))
const AboutUs = lazy( () => import( './routes/bedubyte/about-us/about-us.component'))
const ContactUs = lazy( () => import( './routes/bedubyte/contact-us/contact-us.component'))
const Posts = lazy( () => import( './routes/bedubyte/posts/posts.component'))
const Projects = lazy( () => import( './routes/bedubyte/projects/projects.component'))
const Root = lazy( () => import( './routes/root.component'))
const ManagementRoot = lazy( () => import( './routes/management/management-root.component'))
const AdminDashboard = lazy( () => import( './routes/management/admin/admin-dashboard.component'))
const EndUserRoot = lazy(() =>import('./routes/end-user/end-user-root.component'))
const Home = lazy(()=> import('./routes/bedubyte/home/home.component'))
const Errors = lazy(()=>import('./routes/error/error.component'))
const Login = lazy(()=>import('./components/bedubyte/auth/login.component'))
const Signup = lazy(()=>import('./components/bedubyte/auth/signup.component'))
const ForgotPassword = lazy(()=>import('./components/bedubyte/auth/forgot-password.component'))
const ResetPassword = lazy(()=>import('./components/bedubyte/auth/reset-password.component'))
const VerificationTokenMessage = lazy(()=>import('./components/bedubyte/auth/verification-token-message.component'))
const AboutMe = lazy(()=>import('./routes/end-user/current-user-profile/about/about-me.component'))

const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={<Root/>} errorElement={<Errors/>}>
    <Route path='sitemap/:type' element={<Sitemap/>} />
    <Route path='/' element={<EndUserRoot/>}>
      <Route index element={<Welcome/>} />
      <Route path='home' element={<Home/>} />
      <Route path='about-us' element={<AboutUs/>} />
      <Route path='terms-of-service' element={<TermsOfService/>} />
      <Route path='privacy-policy' element={<PrivacyPolicy/>} />
      <Route path='faqs' element={<FAQs/>} />
      <Route path='getting-started'>
        <Route  index element={<Guides/>}/>
        <Route  path=':guideName' element={<UserGuide/>} loader={guideLoader}/>
      </Route>
      <Route path='projects'>
        <Route  index element={<Projects/>}/>
        <Route  path=':projectId' element={<Project/>} loader={projectLoader}/>
      </Route>
      {/* <Route path='services-and-pricing' element={<ServicesAndPricing/>} /> */}
      <Route path='posts'>
        <Route  index element={<Posts/>}/>
        <Route  path=':postId' element={<BlogPost/>} loader={postLoader}/>
      </Route>
      <Route path='discover' element={<Discover/>} />
      <Route path='skills-and-services' element={<ServicesAndPricing/>} />
      <Route path='contact-us' element={<ContactUs/>} />
      <Route path='create-edit-post/:postId' element={<CreateEditPost mode={MANAGEMENT_MODES.USER_MODE} />}/>
      <Route path=':username/' loader={currentUserProfileLoader} element={<CurrentProfile/>}>
        <Route  index element={<AboutMe/>}/>
        <Route  path='about-me' element={<AboutMe/>}/>
        <Route  path='portfolio'>
          <Route  index element={<Projects/>}/>
          <Route  path=':projectName' element={<Project/>}/>
        </Route>
        <Route  path='services-and-pricing' element={<ServicesAndPricing/>}/>
        <Route  path='resume' element={<Resume/>}/>
        <Route  path='blog'>
          <Route  index element={<Posts/>}/>
          <Route  path=':postName' element={<BlogPost/>}/>
        </Route>
        <Route  path='contact-me' element={<ContactMe/>}/>
      </Route>
      <Route path="auth/">
          <Route index element={<Login/>}/>
          <Route path="login" element={<Login/>}/>
          <Route path="logout" element={<Logout/>}/>
          <Route path="signup" element={<Signup/>}/>
          <Route path="forgot-password" element={<ForgotPassword/>}/>
          <Route path="reset-password" element={<ResetPassword/>}/>
          <Route path="password-reset-token" element={<VerificationTokenMessage tokenType={AUTH_TOKEN_TYPES.PASSWORD_RESET_TOKEN}/>}/>
          <Route path="verify-email" element={<VerificationTokenMessage tokenType={AUTH_TOKEN_TYPES.EMAIL_VERIFICATION_TOKEN}/>}/>
      </Route>
      <Route path='error' element={<Errors/>}/>
    </Route>
    <Route path='admin/'  element={<ManagementRoot mode={MANAGEMENT_MODES.ADMIN_MODE}/>}>
      <Route index element={<AdminDashboard/>}/>
      <Route path='dashboard' element={<AdminDashboard/>}/>
      <Route path='users'>
        <Route index element={<ListUser/>}/>
        <Route path='add/:userId' loader={userToAddLoader} element={<AddEditUser/>}/>
        <Route path='edit/:userId' loader={userToEditLoader} element={<AddEditUser/>}/>
      </Route>
      <Route path='roles'>
        <Route index element={<ListRole/>}/>
        <Route path='add/:roleId' loader={roleToAddLoader} element={<AddEditRole/>}/>
        <Route path='edit/:roleId' loader={roleToEditLoader} element={<AddEditRole/>}/>
      </Route>
      <Route path='privileges'>
        <Route index element={<ListPrivilege/>}/>
        <Route path='add/:privilegeId' loader={privilegeToAddLoader} element={<AddEditPrivilege/>}/>
        <Route path='edit/:privilegeId' loader={privilegeToEditLoader} element={<AddEditPrivilege/>}/>
      </Route>
      <Route path='post-categories'>
        <Route index element={<ListPostCategory mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:postCategoryId' loader={postCategoryToAddLoader} element={<AddEditPostCategory mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:postCategoryId' loader={postCategoryToEditLoader} element={<AddEditPostCategory mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='settings'>
        <Route index element={<Settings mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='profile'>
          <Route index element={<ListProfile mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:profileId' loader={profileToAddLoader} element={<AddEditProfile mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:profileId' loader={profileToEditLoader} element={<AddEditProfile mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='resume'>
          <Route index element={<ListResume mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:resumeId' loader={resumeToAddLoader} element={<AddEditResume mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:resumeId' loader={resumeToEditLoader} element={<AddEditResume mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='follow-policy'>
          <Route index element={<FollowPolicySettings mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='notifications'>
          <Route index element={<NotificationSettings mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
      </Route>
      <Route path='notifications'>
        <Route index element={<NotificationDashboard mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='send' element={<SendNotification mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='read/:notificationId' loader={viewNotificationLoader} element={<ReadNotification mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='posts'>
        <Route index element={<ListPost mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:postId' loader={postToAddLoader} element={<AddEditPost mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:postId' loader={postToEditLoader} element={<AddEditPost mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='projects'>
        <Route index element={<ProjectsMenu mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='project-categories'>
          <Route index element={<ListProjectCategory/>}/>
          <Route path='add/:projectCategoryId' loader={projectCategoryToAddLoader} element={<AddEditProjectCategory/>}/>
          <Route path='edit/:projectCategoryId' loader={projectCategoryToEditLoader} element={<AddEditProjectCategory/>}/>
        </Route>
        <Route path='projects'>
          <Route index element={<ListProject mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:projectId' loader={projectToAddLoader} element={<AddEditProject mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:projectId' loader={projectToEditLoader} element={<AddEditProject mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='featured'>
          <Route index element={<ListFeaturedProject mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:projectId' loader={featuredToAddLoader} element={<AddEditFeaturedProject mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:featuredId' loader={featuredToEditLoader} element={<AddEditFeaturedProject mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='efficiencies'>
          <Route index element={<ListProjectEfficiency mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path=':projectId' loader={efficiencyLoader} element={<AddEditProjectEfficiency mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='sales-generated'>
          <Route index element={<ListProjectSalesgenerated mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path=':projectId' loader={salesGeneratedLoader} element={<AddEditProjectSalesGenerated mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='overall-costs'>
          <Route index element={<ListProjectOverallCosts mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path=':projectId' loader={overallCostLoader} element={<AddEditProjectOverallCost mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='customer-satisfactions'>
          <Route index element={<ListProjectCustomerSatisfactions mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path=':projectId' loader={customerSatisfactionLoader} element={<AddEditProjectCustomerSatisfaction mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
      </Route>
      <Route path='resume'>
        <Route index element={<ResumeMenu mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='education'>
          <Route index element={<ListEducation mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:educationId' loader={educationToAddLoader} element={<AddEditEducation mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:educationId' loader={educationToEditLoader} element={<AddEditEducation mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='awards'>
          <Route index element={<ListAward mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:awardId' loader={awardToAddLoader} element={<AddEditaward mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:awardId' loader={awardToEditLoader} element={<AddEditaward mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='interests'>
          <Route index element={<ListInterest mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:interestId' loader={interestToAddLoader} element={<AddEditInterest mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:interestId' loader={interestToEditLoader} element={<AddEditInterest mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='languages'>
          <Route index element={<ListLanguage mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:languageId' loader={languageToAddLoader} element={<AddEditLanguage mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:languageId' loader={languageToEditLoader} element={<AddEditLanguage mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='work-experiences'>
          <Route index element={<ListWorkExperience mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:workExperienceId' loader={workExperienceToAddLoader} element={<AddEditWorkExperience mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:workExperienceId' loader={workExperienceToEditLoader} element={<AddEditWorkExperience mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='skills'>
          <Route index element={<ListSkill mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:skillId' loader={skillToAddLoader} element={<AddEditSkill mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:skillId' loader={skillToEditLoader} element={<AddEditSkill mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='certifications'>
          <Route index element={<ListCertification mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:certificationId' loader={certificationToAddLoader} element={<AddEditCertification mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:certificationId' loader={certificationToEditLoader} element={<AddEditCertification mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
        <Route path='referees'>
          <Route index element={<ListReferee mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='add/:refereeId' loader={refereeToAddLoader} element={<AddEditReferee mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
          <Route path='edit/:refereeId' loader={refereeToEditLoader} element={<AddEditReferee mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        </Route>
      </Route>
      <Route path='packages'>
        <Route index element={<ListPackage mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:packageId' loader={packageToAddLoader} element={<AddEditPackage mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:packageId' loader={packageToEditLoader} element={<AddEditPackage mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='faqs'>
        <Route index element={<ListFaq mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:faqId' loader={faqToAddLoader} element={<AddEditFaq mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:faqId' loader={faqToEditLoader} element={<AddEditFaq mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='files'>
        <Route index element={<ListFiles mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:fileId' loader={fileToAddLoader} element={<AddEditFile mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:fileId' loader={fileToEditLoader} element={<AddEditFile mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='promos'>
        <Route index element={<ListPromo mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:promoId' loader={promoToAddLoader} element={<AddEditPromo mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:promoId' loader={promoToEditLoader} element={<AddEditPromo mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
      <Route path='guides'>
        <Route index element={<ListGuide mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='add/:guideId' loader={guideToAddLoader} element={<AddEditGuide mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
        <Route path='edit/:guideId' loader={guideToEditLoader} element={<AddEditGuide mode={MANAGEMENT_MODES.ADMIN_MODE}/>}/>
      </Route>
    </Route>
    <Route path='my/'  element={<ManagementRoot mode={MANAGEMENT_MODES.USER_MODE}/>}>
      <Route index element={<MyDashboard/>}/>
      <Route path='dashboard' element={<MyDashboard/>}/>
      <Route path='account' element={<MyAccount/>}/>
      <Route path='edit-account' element={<EditMyAccount/>}/>
      <Route path='edit-profile-picture' element={<EditProfilePicture/>}/>
      <Route path='edit-location' element={<EditLocation/>}/>
      <Route path='settings'>
        <Route index element={<Settings mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='profile'>
          <Route index element={<ListProfile mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:profileId' loader={profileToEditLoader} element={<AddEditProfile mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='resume'>
          <Route index element={<ListResume mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:resumeId' loader={resumeToEditLoader} element={<AddEditResume mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='follow-policy'>
          <Route index element={<FollowPolicySettings mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='notifications'>
          <Route index element={<NotificationSettings mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='password'>
          <Route index element={<Password mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
      </Route>
      <Route path='notifications'>
        <Route path='read/:notificationId' loader={viewNotificationLoader} element={<ReadNotification mode={MANAGEMENT_MODES.USER_MODE}/>}/>
      </Route>
      <Route path='posts'>
        <Route index element={<ListPost mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='add/:postId' loader={postToAddLoader} element={<AddEditPost mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='edit/:postId' loader={postToEditLoader} element={<AddEditPost mode={MANAGEMENT_MODES.USER_MODE}/>}/>
      </Route>
      <Route path='projects'>
        <Route index element={<ProjectsMenu mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='projects'>
          <Route index element={<ListProject mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:projectId' loader={projectToAddLoader} element={<AddEditProject mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:projectId' loader={projectToEditLoader} element={<AddEditProject mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='featured'>
          <Route index element={<ListFeaturedProject mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:projectId' loader={featuredToAddLoader} element={<AddEditFeaturedProject mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:featuredId' loader={featuredToEditLoader} element={<AddEditFeaturedProject mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='efficiencies'>
          <Route index element={<ListProjectEfficiency mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path=':projectId' loader={efficiencyLoader} element={<AddEditProjectEfficiency mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='sales-generated'>
          <Route index element={<ListProjectSalesgenerated mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path=':projectId' loader={salesGeneratedLoader} element={<AddEditProjectSalesGenerated mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='overall-costs'>
          <Route index element={<ListProjectOverallCosts mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path=':projectId' loader={overallCostLoader} element={<AddEditProjectOverallCost mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='customer-satisfactions'>
          <Route index element={<ListProjectCustomerSatisfactions mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path=':projectId' loader={customerSatisfactionLoader} element={<AddEditProjectCustomerSatisfaction mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
      </Route>
      <Route path='resume'>
        <Route index element={<ResumeMenu mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='education'>
          <Route index element={<ListEducation mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:educationId' loader={educationToAddLoader} element={<AddEditEducation mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:educationId' loader={educationToEditLoader} element={<AddEditEducation mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='awards'>
          <Route index element={<ListAward mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:awardId' loader={awardToAddLoader} element={<AddEditaward mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:awardId' loader={awardToEditLoader} element={<AddEditaward mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='interests'>
          <Route index element={<ListInterest mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:interestId' loader={interestToAddLoader} element={<AddEditInterest mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:interestId' loader={interestToEditLoader} element={<AddEditInterest mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='languages'>
          <Route index element={<ListLanguage mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:languageId' loader={languageToAddLoader} element={<AddEditLanguage mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:languageId' loader={languageToEditLoader} element={<AddEditLanguage mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='work-experiences'>
          <Route index element={<ListWorkExperience mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:workExperienceId' loader={workExperienceToAddLoader} element={<AddEditWorkExperience mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:workExperienceId' loader={workExperienceToEditLoader} element={<AddEditWorkExperience mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='skills'>
          <Route index element={<ListSkill mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:skillId' loader={skillToAddLoader} element={<AddEditSkill mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:skillId' loader={skillToEditLoader} element={<AddEditSkill mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='certifications'>
          <Route index element={<ListCertification mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:certificationId' loader={certificationToAddLoader} element={<AddEditCertification mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:certificationId' loader={certificationToEditLoader} element={<AddEditCertification mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
        <Route path='referees'>
          <Route index element={<ListReferee mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='add/:refereeId' loader={refereeToAddLoader} element={<AddEditReferee mode={MANAGEMENT_MODES.USER_MODE}/>}/>
          <Route path='edit/:refereeId' loader={refereeToEditLoader} element={<AddEditReferee mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        </Route>
      </Route>
      <Route path='faqs'>
        <Route index element={<ListFaq mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='add/:faqId' loader={faqToAddLoader} element={<AddEditFaq mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='edit/:faqId' loader={faqToEditLoader} element={<AddEditFaq mode={MANAGEMENT_MODES.USER_MODE}/>}/>
      </Route>
      <Route path='packages'>
        <Route index element={<ListPackage mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='add/:packageId' loader={packageToAddLoader} element={<AddEditPackage mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='edit/:packageId' loader={packageToEditLoader} element={<AddEditPackage mode={MANAGEMENT_MODES.USER_MODE}/>}/>
      </Route>
      <Route path='files'>
        <Route index element={<ListFiles mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='add/:fileId' loader={fileToAddLoader} element={<AddEditFile mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='edit/:fileId' loader={fileToEditLoader} element={<AddEditFile mode={MANAGEMENT_MODES.USER_MODE}/>}/>
      </Route>
      <Route path='promos'>
        <Route index element={<ListPromo mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='add/:promoId' loader={promoToAddLoader} element={<AddEditPromo mode={MANAGEMENT_MODES.USER_MODE}/>}/>
        <Route path='edit/:promoId' loader={promoToEditLoader} element={<AddEditPromo mode={MANAGEMENT_MODES.USER_MODE}/>}/>
      </Route>
    </Route>
  </Route>
))

function App() {
  return (
    <Suspense fallback={<Spinner/>}>
      <HelmetProvider>
        <GlobalStyle/>
          <RouterProvider router={router}/>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
